<template>
    <div class="veranstaltung">
      <script v-html=navigationJsonLd() type="application/ld+json"/>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1 is-size-2-mobile" style="font-family: 'Kaushan Script';">
                        Veranstaltungen
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Denn wo zwei oder drei versammelt sind in meinem Namen, da bin ich mitten unter ihnen.
                        Matthäus 18,20
                    </h2>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">

                <div class="columns">
                    <div class="column">

                        <button class="button is-light is-hidden-tablet" style="margin-bottom: 20px" v-on:click="showFilter()">
                            <font-awesome-icon :icon="['fa', 'filter']"/> <span style="padding-left:10px">Veranstaltungen filtern</span>
                        </button>

                        <div v-show="isShowFilter">

                        <div class="buttons">
                            <button class="button"
                                    :class="isNoVeranstaltungSelected() ? 'is-link' : 'is-primary'"
                                    v-on:click="resetFilterlist()">
                                <font-awesome-icon v-if="isNoVeranstaltungSelected()" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isNoVeranstaltungSelected()" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Alle anzeigen</span>
                            </button>
                        </div>
                        <div class="buttons">

                            <button class="button"
                                    :class="isVeranstaltungSelected('GOTTESDIENST') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'GOTTESDIENST', name: 'Gottedienst'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('GOTTESDIENST')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('GOTTESDIENST')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Gottesdienst</span>
                            </button>

                            <button class="button"
                                    :class="isVeranstaltungSelected('GEMEINDEEVENTS') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'GEMEINDEEVENTS', name: 'Gemeindeevents'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('GEMEINDEEVENTS')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('GEMEINDEEVENTS')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Gemeindeevents</span>
                            </button>

                            <button class="button"
                                    :class="isVeranstaltungSelected('SPOTLIGHT') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'SPOTLIGHT', name: 'Spotlight'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('SPOTLIGHT')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('SPOTLIGHT')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Spotlight</span>
                            </button>


                            <button class="button"
                                    :class="isVeranstaltungSelected('MITTWOCHBIBELSCHULE') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'MITTWOCHBIBELSCHULE', name: 'Mittwochsbibelschule'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('MITTWOCHBIBELSCHULE')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('MITTWOCHBIBELSCHULE')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Mittwochsbibelschule</span>
                            </button>

                            <button class="button"
                                    :class="isVeranstaltungSelected('GEBETSABEND') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'GEBETSABEND', name: 'Gebetsabend'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('GEBETSABEND')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('GEBETSABEND')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Gebetsabend</span>
                            </button>
                        </div>

                        <div class="buttons">
                            <button class="button"
                                    :class="isVeranstaltungSelected('KINDER') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'KINDER', name: 'Kinder'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('KINDER')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('KINDER')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Kinder</span>
                            </button>
                            <button class="button"
                                    :class="isVeranstaltungSelected('TEENAGER') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'TEENAGER', name: 'Teenager'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('TEENAGER')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('TEENAGER')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Teenager</span>
                            </button>
                            <button class="button"
                                    :class="isVeranstaltungSelected('AC') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'AC', name: 'Junge Erwachsene'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('AC')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('AC')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Junge Erwachsene</span>
                            </button>
                            <button class="button"
                                    :class="isVeranstaltungSelected('SENIOREN') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'SENIOREN', name: 'Senioren'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('SENIOREN')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('SENIOREN')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Senioren</span>
                            </button>
                            <button class="button"
                                    :class="isVeranstaltungSelected('FRAUEN') ? 'is-link' : 'is-primary'"
                                    v-on:click="filterVeranstaltung({id: 'FRAUEN', name: 'Frauen'})">
                                <font-awesome-icon v-if="isVeranstaltungSelected('FRAUEN')" :icon="['fa', 'check']"/>
                                <font-awesome-icon v-if="!isVeranstaltungSelected('FRAUEN')" :icon="['fa', 'times']"/>
                                <span style="padding-left:10px">Frauen</span>
                            </button>
                        </div>

                        </div>

                    </div>


                    <div class="column is-four-fifths">
                      <div class="notification is-link is-light" v-if="veranstaltungen != null && !veranstaltungen.length">
                        Es konnte keine Veranstaltung unter den aktuellen Filterkriterien gefunden werden.
                      </div>

                        <div class="columns is-multiline">
                            <div v-for="veranstaltung in veranstaltungen" v-bind:key="veranstaltung.id"
                                 class="column is-half-desktop is-one-third-widescreen is-full">
                                <script v-html=jsonLd(veranstaltung) type="application/ld+json"/>
                                <KalenderVeranstaltung :titel=veranstaltung.titel
                                                       :beginn=veranstaltung.beginn
                                                       :ende=veranstaltung.ende
                                                       ort="Reinhold-Frank-Straße 44a, 76133 Karlsruhe"
                                                       :beschreibung=veranstaltung.beschreibung
                                                       :image=veranstaltung.image
                                                       :ansprechpartner=veranstaltung.ansprechpartner
                                                       :ansprechpartnerBild=veranstaltung.ansprechpartner_bild
                                                       :ansprechpartnerMail=veranstaltung.ansprechpartner_mail
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>

</template>


<script>
// @ is an alias to /src
import KalenderVeranstaltung from '@/components/KalenderVeranstaltung.vue'

export default {
        name: 'gruppen',
        components: {
            KalenderVeranstaltung
        },
        data() {
            return {
                filterExpandMessage:'Filter Menü öffnen',
                isShowFilter:true,
                filterVeranstaltungen: '',
                veranstaltungen: null,
                selectedVeranstaltungen: []
            }
        },
        methods: {
            jsonLd(veranstaltung) {
              var jsonLdDescription={
                "@context": "https://schema.org",
                "@type": "Event",
                "name": "",
                "startDate": "",
                "endDate": "",
                "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                "eventStatus": "https://schema.org/EventScheduled",
                "location": {
                  "@type": "Place",
                  "name": "Christliche Gemeinde Karlsruhe",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Reinhold-Frank-Str. 44a",
                    "addressLocality": "Karlsruhe",
                    "postalCode": "76133",
                    "addressCountry": "Deutschland"
                  }
                },
                "image": [
                  ""
                ],
                "description": "",
                "organizer": {
                  "@type": "Organization",
                  "name": "Christliche Gemeinde Karlsruhe",
                  "url": "https://www.cg-karlsruhe.de"
                }
              };

              jsonLdDescription['name']=veranstaltung.titel;
              jsonLdDescription['description']=veranstaltung.beschreibung;
              jsonLdDescription['startDate']=veranstaltung.beginn;
              jsonLdDescription['endDate']=veranstaltung.ende;
              jsonLdDescription['image'][0]=veranstaltung.image;

              return jsonLdDescription;

            },
            deleteSelected: function (id) {
                for (var i = 0; i < this.selectedVeranstaltungen.length; i++) {
                    if (this.selectedVeranstaltungen[i].id == id) {
                        this.selectedVeranstaltungen.splice(i, 1);
                    }
                }
                this.updateFilterlist();
            },

            isVeranstaltungSelected: function (id) {
                for (var i = 0; i < this.selectedVeranstaltungen.length; i++) {
                    if (this.selectedVeranstaltungen[i].id == id) {
                        return true;
                    }
                }
                return false;
            },
            filterVeranstaltung: function (veranstaltung) {

                if (this.isVeranstaltungSelected(veranstaltung.id)) {
                    this.deleteSelected(veranstaltung.id);
                    return;
                }

                for (var i = 0; i < this.selectedVeranstaltungen.length; i++) {
                    if (this.selectedVeranstaltungen[i].id == veranstaltung.id) {
                        return;
                    }
                }

                this.selectedVeranstaltungen.push(veranstaltung);
                this.updateFilterlist();
            },
            resetFilterlist: function () {
                this.selectedVeranstaltungen = [];
                this.updateFilterlist();
            },
            isNoVeranstaltungSelected: function () {
                return (this.selectedVeranstaltungen.length == 0);
            },
            updateFilterlist: function () {
                var filterString = "";
                for (var i = 0; i < this.selectedVeranstaltungen.length; i++) {
                    filterString += this.selectedVeranstaltungen[i].id + ',';
                }

                this.$cgrest
                    .get('/veranstaltungen?filter=' + filterString)
                    .then(response => (this.veranstaltungen = response.data))
            },
            isMobile: function () {
                if( screen.width <= 760 ) {
                    return true;
                }
                else {
                    return false;
                }
            },
            showFilter: function () {
                this.isShowFilter = !this.isShowFilter;
            },
            navigationJsonLd() {
              var jsonLdBreadcrumb= {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "CG Karlsruhe",
                  "item": "https://www.cg-karlsruhe.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Veranstaltungen",
                  "item": "https://www.cg-karlsruhe.de/veranstaltungen"
                }]
              };
              return jsonLdBreadcrumb;
            }
        },
        mounted() {
            this.$cgrest
                .get('/veranstaltungen')
                .then(response => (this.veranstaltungen = response.data));
            if(this.isMobile()) {
                this.isShowFilter=false;
            }
        },
    }
</script>